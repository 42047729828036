import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 页面模板
import Layout from '@/layout/Layout'
// 普通路由
export const constantRouterMap = [
  {
    path: '/',
    component: () => import (/* webpackChunkName: "Login" */ '@/page/Login')
  },
  {
    path: '/statistics',
    component: () => import (/* webpackChunkName: "Login" */ '@/page/Statistics')
  },
  {
    path: '/energyConservationRegulation',
    component: () => import (/* webpackChunkName: "Login" */ '@/page/energyConservationRegulation')
  },
  {
    path: '/CarbonManagement', // 地址
    component: Layout, // 注意component没得s
    redirect: '/CarbonManagement/index', // 只要访问home组件就重定向到welcome
    name: '碳排放管理',
    children: [{
      path: '/CarbonManagement/index', // 地址
      component: () => import('@/page/CarbonManagement/index'),
      name: '活动发布管理',
      meta: {
        keepAlive: true
      }
    }, {
      path: '/CarbonManagement/details', // 地址
      component: () => import('@/page/CarbonManagement/CarbonManagementDetail'),
      name: '活动详情',
      meta: {
        keepAlive: true,
        type: 'details'
      }
    }, {
      path: '/CarbonManagement/add', // 地址
      component: () => import('@/page/CarbonManagement/AddCarbonManagement'),
      name: '创建活动',
      meta: {
        keepAlive: true,
        type: 'addData'
      }
    }, {
      path: '/CarbonManagement/edit', // 地址
      component: () => import('@/page/CarbonManagement/EditCarbonManagement'),
      name: '编辑活动',
      meta: {
        keepAlive: true,
        type: 'editData'
      }
    }]
  },
  {
    path: '/ContentManagement', // 地址
    component: Layout, // 注意component没得s
    redirect: '/ContentManagement', // 只要访问home组件就重定向到welcome
    name: '内容管理',
    children: [{
      path: '/ContentManagement/index', // 地址
      component: () => import('@/page/ContentManagement/index'),
      name: '资讯管理',
      meta: {
        keepAlive: true
      }
    }, {
      path: '/ContentManagement/details', // 地址
      component: () => import('@/page/ContentManagement/details'),
      name: '资讯详情',
      meta: {
        keepAlive: true,
        type: 'details'
      }
    }, {
      path: '/ContentManagement/add', // 地址
      component: () => import('@/page/ContentManagement/AddContentManagement'),
      name: '添加资讯',
      meta: {
        keepAlive: true,
        type: 'addData'
      }
    }, {
      path: '/ContentManagement/edit', // 地址
      component: () => import('@/page/ContentManagement/EditContentManagement'),
      name: '编辑资讯',
      meta: {
        keepAlive: true,
        type: 'editData'
      }
    }]
  },
  {
    path: '/SpecialManagement', // 地址
    component: Layout, // 注意component没得s
    redirect: '/SpecialManagement', // 只要访问home组件就重定向到welcome
    children: [{
      path: '/SpecialManagement/index', // 地址
      component: () => import('@/page/SpecialManagement/index'),
      name: '专项讲解管理',
      meta: {
        keepAlive: true
      }
    }, {
      path: '/SpecialManagement/config', // 地址
      component: () => import('@/page/SpecialManagement/config'),
      name: '新增专项讲解',
      meta: {
        keepAlive: true
      }
    }, {
      path: '/SpecialManagement/config', // 地址
      component: () => import('@/page/SpecialManagement/config'),
      name: '编辑专项讲解',
      meta: {
        keepAlive: true,
        type: 'editData'
      }
    }]
  },
  {
    path: '/RegularUsers', // 地址
    component: Layout, // 注意component没得s
    redirect: '/RegularUsers', // 只要访问home组件就重定向到welcome
    children: [{
      path: '/RegularUsers/index', // 地址
      component: () => import('@/page/RegularUsers/index'),
      name: '普通用户',
      meta: {
        keepAlive: true
      }
    }, {
      path: '/RegularUsers/detail', // 地址
      component: () => import('@/page/RegularUsers/detail'),
      name: '用户详情',
      meta: {
        keepAlive: true
      }
    }]
  },
  {
    path: '/SystemManagement', // 地址
    component: Layout, // 注意component没得s
    redirect: '/SystemManagement', // 只要访问home组件就重定向到welcome
    name: '系统管理',
    children: [{
      path: '/SystemManagement/UserManagement', // 地址 用户管理
      component: () => import('@/page/SystemManagement/UserManagement'),
      name: '用户管理',
      meta: {
        keepAlive: true
      }
    }, {
      path: '/SystemManagement/UserManagementDetails', // 地址
      component: () => import('@/page/SystemManagement/UserManagementDetails'),
      meta: {
        keepAlive: true,
        type: 'details'
      }
    }, {
      path: '/SystemManagement/UnitManagement', // 地址 用户管理
      component: () => import('@/page/SystemManagement/UnitManagement'),
      name: '单位管理',
      meta: {
        keepAlive: true
      }
    }]
  },
  {
    path: '/ConferenceRoomManagement',
    component: Layout,
    redirect: '/ConferenceRoomManagement/index',
    children: [
      {
        path: '/ConferenceRoomManagement/index',
        component: () => import('@/page/ConferenceRoomManagement/index'),
        name: '会议室管理',
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/ConferenceRoomManagement/edit',
        component: () => import('@/page/ConferenceRoomManagement/EditConferenceRoom'),
        name: '编辑会议室',
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/ConferenceRoomManagement/add',
        component: () => import('@/page/ConferenceRoomManagement/AddConferenceRoom'),
        name: '添加会议室',
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/ConferenceRoomManagement/detail',
        component: () => import('@/page/ConferenceRoomManagement/ConferenceRoomDetail'),
        name: '会议室详情',
        meta: {
          keepAlive: true
        }
      },
    ]
  },
  {
    path: '/Tenant',
    component: Layout,
    redirect: '/Tenant/TenantList',
    name: '租户管理',
    children: [
      {
        path: '/Tenant/TenantList',
        component: () => import('@/page/Tenant/TenantList'),
        name: '租户列表',
      },
      {
        path: '/Tenant/TenantConfig/:key',
        component: () => import('@/page/Tenant/TenantConfig'),
        name: '租户配置',
      },
      {
        path: '/System/node-red',
        component: () => import('@/page/System/node-red/Index'),
        name: '组态管理',
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/System/warning',
        component: () => import('@/page/System/warning/Index'),
        name: '告警管理',
        meta: {
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/Logs',
    component: Layout,
    redirect: '/Logs/DeviceLogList',
    name: '日志',
    children: [
      {
        path: '/Logs/DeviceLogList',
        component: () => import('@/page/Logs/DeviceLogList'),
        name: '设备运行日志',
      },
      {
        path: '/Logs/OptLogList',
        component: () => import('@/page/Logs/OptLogList'),
        name: '系统操作日志',
      }
    ]
  },
  {
    path: '/WorkOrder',
    component: Layout,
    redirect: '/WorkOrder/WorkOrderList',
    name: '运维工单',
    children: [
      {
        path: '/WorkOrder/WorkOrderList',
        component: () => import('@/page/WorkOrder/WorkOrderList'),
        name: '运维工单列表',
      },
    ]
  },
  {
    path: '/Automation',
    component: Layout,
    redirect: '/Automation/Index',
    name: '自动化',
    children: [
      {
        path: '/Automation/Index',
        component: () => import('@/page/Automation/Index'),
        name: '自动化列表',
      },
    ]
  },
  {
    path: '/IndicatorLibrary', // 地址
    component: Layout, // 注意component没得s
    redirect: '/IndicatorLibrary/indicatorLibrary', // 只要访问home组件就重定向到welcome
    name: '配置管理',
    children: [{
      path: '/IndicatorLibrary/indicatorLibrary', // 地址
      component: () => import('@/page/IndicatorLibrary/indicatorLibrary'),
      name: '指标库管理',
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/ModuleLibary/index', // 地址
      component: () => import('@/page/ModuleLibary/index'),
      name: '模型库管理',
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/IotDeviceAreaManagement/index',
      component: () => import('@/page/IotDeviceAreaManagement/Index'),
      name: 'IOT设备区域管理',
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/ElectricityPrice/index',
      component: () => import('@/page/ElectricityPrice/Index'),
      name: '电价管理',
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/WeatherManagement/Index',
      component: () => import('@/page/WeatherManagement/Index'),
      name: '天气管理',
      meta: {
        keepAlive: true
      }
    }]
  },
  {
    path: '/System',
    component: Layout,
    redirect: '/System/users',
    name: '系统管理',
    children: [
      {
        path: '/System/users',
        component: () => import('@/page/System/users/UserPanel'),
        name: '用户管理',
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/System/logs',
        component: () => import('@/page/System/logs/Index'),
        name: '操作日志',
        meta: {
          keepAlive: true
        }
      }
    ]
  }
]

// 动态路由
// const PermissionRouting = []
// 错误路由
// const errorRoutes = []

/**
 *  把普通路由、计算出结果的动态路由、错误路由添加至路由。
 *  constantRouterMap: 基本路由
 *  PermissionRouting: 动态/权限路由
 *  errorRoutes: 错误路由
*/
export default new VueRouter({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: [...constantRouterMap]
})

