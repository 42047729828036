// 公共VueX模块

// state
const state = {
  // 环境变量中url
  CommonProcessEnvUrl: "",
  // 登录页面图片
  CommonLoginImg: require("@/assets/img/loginImg.png"),
  // from尺寸
  CommonFormSize: "medium",
  // 公共Message消息弹窗自动关闭时间（ms）
  CommonMessageDuration: 2000,
  // 公共setTimeout倒计时（ms）
  CommonSetTimeout: 3000,
  // 默认头部高度
  CommonHeaderHeight: "70px",
  // 头部过度动画
  CommonHeadershow: false,
  // 内容过度动画状态
  CommonElMainshow: false,
  // Aside过度动画
  CommonAsideShow: false,
  // 公共按钮尺寸
  CommonButtonSize: "mini",
  // 导航是否展开
  CommonShrinkageStart: false,
  // 是否只保持一个子菜单的展开
  CommonUniqueOpened: false,
  // 是否开启折叠动画
  CommonCollapseTransition: true,
  // logo图片地址
  CommonLogoUrl: require('@/assets/img/logo.jpg'),
  // 导航背景
  CommonMenuBackgroundColor: "#2592e8",
  // 导航文字颜色
  CommonMenuTextColor: "#fff",
  // 导航文字选中
  CommonMenuActiveTextColor: "#fff",
  // 导航默认选择
  CommonMenuDefaultActive: "/CarbonManagement/index",
  // 导航默认选择
  CommonDefaultActive: "",
  // 判断是否展示底部（翻页）
  CommonElMainHeightStart: false,
  // 导航数据
  CommonAsideMenusList: [
    {
      id: "101",
      authName: "会议",
      path: "/CarbonManagement/",
      Icon: "el-icon-s-cooperation",
      children: [
        {
          id: "10101",
          authName: "会议管理",
          path: "/CarbonManagement/index",
          show: true,
        },
        {
          id: "10102",
          authName: "会议详情",
          path: "/CarbonManagement/details",
          show: false,
        },
        {
          id: "10103",
          authName: "创建会议",
          path: "/CarbonManagement/add",
          show: false,
        },
        {
          id: "10104",
          authName: "编辑会议",
          path: "/CarbonManagement/edit",
          show: false,
        },
        {
          id: "10105",
          authName: "会议室",
          path: "/ConferenceRoomManagement",
          show: true,
        },
        {
          id: "10106",
          authName: "资讯管理",
          path: "/ContentManagement/index",
          show: true,
        },
        {
          id: "10107",
          authName: "专项讲解管理",
          path: "/SpecialManagement/index",
          show: true,
        },
        {
          id: "10108",
          authName: "普通用户",
          path: "/RegularUsers/index",
          show: true,
        },
      ],
    },
    {
      id: "201",
      authName: "租户管理",
      path: "/SystemManagement/UserManagement",
      Icon: "el-icon-s-custom",
      children: [
        {
          id: "20101",
          authName: "租户列表",
          path: "/Tenant/TenantList",
          show: true,
        },
        // {
        //   id: '20101',
        //   authName: '数据分析',
        //   path: '/statistics',
        //   show: true
        // },
        {
          id: "20102",
          authName: "组态管理",
          path: "/System/node-red",
          show: true,
        },
        {
          id: "20103",
          authName: "告警管理",
          path: "/System/warning",
          show: true,
        },
        {
          id: '20104',
          authName: '节能监管',
          path: '/energyConservationRegulation',
          show: true
        },
        // {
        //   id: '20102',
        //   authName: '用户管理',
        //   path: '/SystemManagement/UserManagement',
        //   show: true
        // },
      ],
    },
    // {
    //   id: "401",
    //   authName: "运维",
    //   Icon: "el-icon-s-order",
    //   path: "/WorkOrder/WorkOrderList",
    //   children: [
    //     {
    //       id: "40101",
    //       authName: "运维工单",
    //       path: "/WorkOrder/WorkOrderList",
    //       show: true,
    //     },
    //     {
    //       id: "40102",
    //       authName: "自动化",
    //       path: "/Automation/Index",
    //       show: true,
    //     },
    //   ],
    // },
    {
      id: "601",
      authName: "配置管理",
      path: "/IndicatorLibrary/indicatorLibrary",
      Icon: "el-icon-s-help",
      children: [
        {
          id: "60101",
          authName: "指标库管理",
          path: "/IndicatorLibrary/indicatorLibrary",
          show: true,
        },
        {
          id: "60102",
          authName: "模型库管理",
          path: "/ModuleLibary/index",
          show: true,
        },
        {
          id: "60103",
          authName: "IOT设备区域管理",
          path: "/IotDeviceAreaManagement/index",
          show: true,
        },
        {
          id: "60104",
          authName: "电价管理",
          path: "/ElectricityPrice/index",
          show: true,
        },
        {
          id: "60105",
          authName: "天气管理",
          path: "/WeatherManagement/Index",
          show: true,
        },
      ],
    },
    {
      id: "701",
      authName: "系统管理",
      path: "/System",
      Icon: "el-icon-setting",
      children: [
        {
          id: "70101",
          authName: "用户管理",
          path: "/System/users",
          show: true,
        },
        {
          id: "70102",
          authName: "操作日志",
          path: "/System/logs",
          show: true,
        },
      ],
    }
  ],
  // 面包屑
  CommonBreadcrumbList: [],
  // 当前页数，支持 .sync 修饰符
  CommonCurrentPage: 1,
  // 每页显示个数选择器的选项设置
  CommonPageSizes: [20, 40, 60, 80, 100, 120],
  // 每页显示条目个数，支持 .sync 修饰符
  CommonPageSize: 20,
  // 总条目数
  CommonTotal: 0,
  // 表单label宽
  CommonLabelWidth: "200px",
  // 表单尺寸
  CommonSize: "small",
  // 图片上传上限
  CommonUploadLimit: 5,
  // dialog统一宽度
  CommonDialogWidth: "40vw",
  // iot树
  iotTreeList: [],
};
// getters
const getters = {};
// mutations
const mutations = {
  // 获取环境变量中的URL
  GetCommonProcessEnvUrl(state, start) {
    state.CommonProcessEnvUrl = start;
  },
  // Aside过度动画状态修改
  ChangeAsideShow(state, start) {
    state.CommonAsideShow = start;
  },
  // Header过度动画状态修改
  ChangeHeadershow(state, start) {
    state.CommonHeadershow = start;
  },
  // 内容过度动画状态修改
  ChangeElMainshow(state, start) {
    state.CommonElMainshow = start;
  },
  // 折叠/收缩
  ChangeShrinkageStart(state, start) {
    state.CommonShrinkageStart = start;
  },
  // 获取点击导航
  GetCommonDefaultActive(state, start) {
    state.CommonDefaultActive = start;
  },
  // 获取面包屑
  GetCommonBreadcrumbList(state, start) {
    state.CommonBreadcrumbList = start;
  },
  // 判断是否展示底部（翻页）
  GetCommonElMainHeightStart(state, start) {
    state.CommonElMainHeightStart = start;
  },
  // 获取一页展示多少条数据
  GetHandleSizeChange(state, start) {
    state.CommonPageSize = start;
  },
  // 获取当前页
  GetHandleCurrentChange(state, start) {
    state.CommonCurrentPage = start;
  },
  // 获取总条数
  GetCommonTotal(state, start) {
    state.CommonTotal = start;
  },
  // 路由改变初始化分页数据
  InItCurrentStart(state, start) {
    // 当前页
    state.CommonCurrentPage = start.CommonCurrentPage;
    // 每页显示条目个数
    state.CommonPageSize = start.CommonPageSize;
    // 总条目数
    state.CommonTotal = start.CommonTotal;
  },
  // 租户信息
  setIotTreeList(state, start) {
    state.iotTreeList = start;
  },
};
// actions
const actions = {};
// 导出
export default {
  namespaced: true, // 命名空间：namesapced:true，当模块被注册后，它的所有getter、action及mutation都会自动根据模块注册的路径调整命名，也就是说，我们在调用这些方法时，需要加上这个文件的路径(比如我要访问这个文件中的state里边的某个属性：this.store.state.Commint。后边这个Commint就是多了个Commint.js模块名，因为如果不加模块名，那我们会访问所有模块中的属性，那就乱了)，所以我们要加上命名空间，相当于独立的区块去使用，模块和模块之间互不干扰。
  state,
  getters,
  mutations,
  actions,
};
